import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modulos/auth/login/login.component';
import { PerfilPageComponent } from './modulos/profile/perfil/perfil.component';
import { IndiqueComponent } from './modulos/profile/indique/indique.component';
import { HomeComponent } from './modulos/content/home/home.component';
import { OngsComponent } from './modulos/content/ongs/ongs.component';
import { FaqComponent } from './modulos/content/faq/faq.component';
import { DescontosComponent } from './modulos/benefits/descontos/descontos.component';
import { LeilaoComponent } from './modulos/benefits/leilao/leilao.component';
import { ProdutosComponent } from './modulos/benefits/produtos/produtos.component';
import { ExtratoComponent } from './modulos/benefits/extrato/extrato.component';
import { BoletosComponent } from './modulos/benefits/boletos/boletos.component';
import { CadastroPontosComponent } from './modulos/benefits/cadastro-pontos/cadastro-pontos.component';
import { FaleConoscoComponent } from './modulos/benefits/fale-conosco/fale-conosco.component';
import { CadastroComponent } from './modulos/auth/cadastro/cadastro.component';
import { PrimeiroAcessoComponent } from './modulos/auth/primeiro-acesso/primeiro-acesso.component';
import { IndexComponent } from './modulos/content/index/index.component';
import { LandingComponent } from './modulos/content/landing/landing.component';

const routes: Routes = [
  {    path: '',                    component: LandingComponent },
  {    path: 'perfil',              component: PerfilPageComponent},
  {    path: 'indique',             component: IndiqueComponent},

  {    path: 'home',                component: HomeComponent },
  {    path: 'ongs',                component: OngsComponent},
  {    path: 'faq',                 component: FaqComponent},
    
  {    path: 'beneficios',          component: DescontosComponent},
  {    path: 'leilao',              component: LeilaoComponent},
  {    path: 'produtos',            component: ProdutosComponent},
  {    path: 'extrato',             component: ExtratoComponent},
  {    path: 'boleto',              component: BoletosComponent},
  {    path: 'descontos',           component: DescontosComponent},
  {    path: 'qrscan',              component: CadastroPontosComponent},
  {    path: 'fale-conosco',        component: FaleConoscoComponent},

   
  {    path: 'login',               component: LoginComponent  },
  {    path: 'cadastro',            component: CadastroComponent  },
  {    path: 'cadastrar',           component: CadastroComponent  },
  {    path: 'indicacao/:id',       component: CadastroComponent  },
  {    path: 'primeiro-acesso',     component: PrimeiroAcessoComponent},
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
