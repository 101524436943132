import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { ProdutosService } from 'src/app/services/produtos.service';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.scss'],
})
export class ProdutosComponent implements OnInit {

  cnpjForm: FormGroup;
  modal: HTMLIonModalElement;

  user_id;
  user_name;
  pontos;
  moedas;
  foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  palavraChave = '';
  paginaAtual = 1;
  totalPaginas = 1;
  paginasVisiveis = [];
  produtos: any = [];
  modal_ativo;
  modal_banner_url;
  confirmacaoAceita: boolean = false;
  ordenacao: string = '';
  loading = false;
  produtoSelecionado;
  confirmarEndereco: boolean = false;
  pedidoConfirmado: boolean = false;
  error_msg;
  isModalOpen = false;
  cnpj: string;
  dataNascimento: string;
  usuario: any = {};

  @ViewChild('modalAcao', { static: false }) private modalAcao: TemplateRef<any>;

  regulamento = false;
  is_desktop = false;

  constructor(private route: ActivatedRoute, private router: Router, private modalService: ModalController, 
              private sanitizer: DomSanitizer, private http: HttpClient, private loader: NgxUiLoaderService, 
              private produtosService: ProdutosService) {
  }

  ngOnInit() {
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');

    if (!this.user_id) {
      this.router.navigate(['/login']);
    }

    this.obter_produtos();
    this.obter_dados();
  }

  obter_dados() {
    this.http.get<any>(`${environment.API_URL}pontos/index?id_participante=${this.user_id}`).subscribe(data => {
      this.pontos = data.pontos;
      this.moedas = data.moedas;
    });
  }

  obter_produtos() {
    this.loading = true;
    const filtro = {
      'id_participante': this.user_id,
      'limit': 6,
      'pagina': this.paginaAtual,
      'search': this.palavraChave,
      'preco': this.ordenacao
    }
    this.produtos = [];
    this.produtosService.obterProdutos(filtro).subscribe({
      next: (response) => {
        if (response.status === 1) {
          this.produtos = Object.values(response.produtos);
          this.totalPaginas = response.paginas;
          this.calcularPaginas();
          this.loading = false;
        }
        if (response.status === 0) {
          alert(response.error);
        }
      },
      error: (err) => console.error('Erro ao obter produtos:', err)
    });
  }

  filtrar() {
    this.paginaAtual = 1;
    this.obter_produtos();
  }

  setPagina(pagina: number): void {
    this.paginaAtual = pagina;
    this.obter_produtos();
  }

  calcularPaginas(): void {
    const maxVisiveis = 6;
    let inicio = Math.max(this.paginaAtual - Math.floor(maxVisiveis / 2), 1);
    let fim = inicio + maxVisiveis - 1;

    if (fim > this.totalPaginas) {
      fim = this.totalPaginas;
      inicio = Math.max(fim - maxVisiveis + 1, 1);
    }

    this.paginasVisiveis = Array.from({ length: (fim - inicio + 1) }, (_, i) => inicio + i);
  }

  selecionarProduto(id) {
    this.loading = true;
    this.produtosService.obterProduto({ id_produto: id, id_participante: this.user_id }).subscribe({
      next: (response) => {
        if (response.status === 1) {
          this.produtoSelecionado = response.produto;
          this.loading = false;
        }
      },
      error: (err) => console.error('Erro ao obter produtos:', err)
    });
  }

  resgatar() {
    this.obter_dados_usuario().subscribe(userData => {
      this.usuario = userData.usuario;
      if (!this.usuario.cnpj) {
        this.abrirModalCNPJ();
      } else {
        this.confirmarEndereco = true;
      }
    }, error => {
      console.error('Erro ao obter dados do usuário:', error);
    });
  }

  async abrirModalCNPJ() {
    this.isModalOpen = true;
  }

  fecharModal() {
    this.isModalOpen = false;
  }

  salvarCNPJ() {
    if(!this.validarCNPJ(this.cnpj)){
      alert("CNPJ invalido");
      return false;
    }

    if (this.dataNascimento.length < 8) {
        alert('Verifique o preenchimento, o campo ANIVERSARIO é obrigatorio.');
        return false;
    }

    const dataAtual = new Date();
    const mes = parseInt(this.dataNascimento.substring(2, 4));
    const ano = parseInt(this.dataNascimento.substring(4, 8));

    if (mes > 12 || mes === 0) {
        alert("Verifique o formato do campo ANIVERSARIO.");
        return false;
    }

    const idade = dataAtual.getFullYear() - ano - (dataAtual.getMonth() < mes - 1 || (dataAtual.getMonth() === mes - 1 && dataAtual.getDate() < parseInt(this.dataNascimento.substring(0, 2))) ? 1 : 0);
    if (idade < 18) {
        alert("Você precisa ter +18 anos para participar.");
        return false;
    }


    const dados = {
      cnpj: this.cnpj,
      aniversario: this.dataNascimento 
    };
    
    this.atualizar_dados(dados).subscribe(response => {
      if (response.status === 1) {
        this.usuario.cnpj = this.cnpj;
        this.usuario.aniversario = this.dataNascimento;
        this.fecharModal();
        this.confirmarEndereco = true;
      } else {
        console.error('Erro ao atualizar dados do usuário:', response.message);
      }
    }, error => {
      console.error('Erro ao atualizar dados do usuário:', error);
    });
  }
  
  obter_dados_usuario() {
    let user_id = localStorage.getItem('userId');
    return this.http.get<any>(`${environment.API_URL}participantes/obter_dados/${user_id}`);
  }

  atualizar_dados(dados) {
    let user_id = localStorage.getItem('userId');

    return this.http.post<any>(`${environment.API_URL}participantes/update`, {
      id: user_id,
      user: {
        cnpj: dados.cnpj,
        aniversario: dados.aniversario
      }
    });

  }

  validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj.length != 14)
        return false;
    // Elimina CNPJs inválidos conhecidos
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;

      // Valida DVs
      let tamanho = cnpj.length - 2
      let numeros = cnpj.substring(0,tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0))
          return false;
      tamanho = tamanho + 1;
      numeros = cnpj.substring(0,tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1))
            return false;

      return true;
  }
    
  selecao($event) {
    this.confirmarEndereco = false;

    if ($event.confirmar) {
      if (window.confirm('Essa ação não pode ser desfeita, tem certeza que deseja resgatar esse produto?')) {
        this.produtosService.fazerPedido(this.produtoSelecionado.id, this.user_id).subscribe({
          next: (response) => {
            if (response.status === 1) {
              this.pedidoConfirmado = true;
            } else {
              this.error_msg = response.error;
            }
          },
          error: (err) => console.error('Erro ao realizar resgate:', err)
        });
      }
    }

    if ($event.editar) {
      this.router.navigate(['/perfil']);
    }
  }
}
