import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-leilao',
  templateUrl: './leilao.component.html',
  styleUrls: ['./leilao.component.scss'],
})
export class LeilaoComponent implements OnInit, OnDestroy {

  leiloes = [];
  lancesRecentes = [];
  leilaoSelecionado: any; 
  mostrarLanceContainer: boolean = false;
  lanceAnimacao: boolean = false;
  atualizarLeilaoAberto: any;
  lanceAtualizado: boolean = false;

  mensagemErro: string = '';

  slidesProdutosMobile = {
    slidesPerView: 1,
    spaceBetween: 10,
    dots: true,
    autoplay: {
      delay: 6000,
    },
    pagination: {
      el: '.swiper-pagination-shares',
    },
  }

  slidesProdutosDesktop = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
    }
  };

  user_id;
  user_name;
  pontos;
  lance_minimo = 0;
  valor_lance = 0;
  atualizar_leiloes;
  meu_lance;
  
  @Input() moedas;

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit() {
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');

    if (!this.user_id) {
      this.router.navigate(['/login']);
    }

    this.obter_leiloes();
    this.obter_dados();
  }

  ngOnDestroy() {
    if (this.leilaoSelecionado && this.mostrarLanceContainer) {
      // Limpar o intervalo de atualização do leilão aberto
      if (this.atualizarLeilaoAberto) {
        clearInterval(this.atualizarLeilaoAberto);
      }
  
      // Verificar se existe um timer associado ao leilão e limpar
      if (this.leilaoSelecionado.timer) {
        clearInterval(this.leilaoSelecionado.timer);
      }
    }
  
    // Limpar intervalos de leilões, se necessário
    this.leiloes.forEach(leilao => {
      if (leilao.timer) {
        clearInterval(leilao.timer);
      }
    });
  }
  
  obter_dados() {
    this.http.get<any>(environment.API_URL.concat('pontos/index?id_participante=' + this.user_id)).subscribe(data => {
      this.pontos = parseInt(data.pontos);
      this.moedas = parseInt(data.moedas);
    });
  }

  obter_leiloes() {
    this.http.get<any>(environment.API_URL.concat('leiloes/obter_ativos')).subscribe(data => {
      this.leiloes = data.leiloes;
      this.leiloes.forEach(leilao => this.iniciarTimer(leilao));
    });
  }

  fazer_lance() {
    const dados = {
      user_id: this.user_id,
      id_leilao: this.leilaoSelecionado.id,
      moedas: this.valor_lance
    };
  
    this.http.post<any>(`${environment.API_URL}leiloes/fazer_lance`, dados).subscribe(data => {
      if (data.status === 1) {
        this.lanceAnimacao = true;
        this.meu_lance = this.valor_lance;

        this.obter_dados();
        this.obter_leiloes();

        setTimeout(() => {
          this.lanceAnimacao = false;
        }, 4500);
        this.mensagemErro = '';
      } else {
        this.mensagemErro = data.mensagem; 
      }
    });
  }

  abrirLeilao(leilaoSelecionado: any) {
    
    // Limpar intervalo anterior, se existir
    if (this.atualizarLeilaoAberto) {
      clearInterval(this.atualizarLeilaoAberto);
    }
  
    this.leilaoSelecionado = leilaoSelecionado;
    this.mostrarLanceContainer = true;
  
    const postData = {
      id_leilao: leilaoSelecionado.id,
      id_usuario: this.user_id 
    };
  
    this.obterMaiorLance(postData);
  
    // Inicia o intervalo para atualizar o maior lance a cada 1 segundo
    this.atualizarLeilaoAberto = setInterval(() => {
      this.obterMaiorLance(postData);
    }, 1500);
  }

  obterMaiorLance(postData: any) {

    this.http.post<any>(`${environment.API_URL}leiloes/obter_maior_lance`, postData).subscribe({
      next: (response) => {

        //antes de atualizar o lance verifica se o anterior é do usuario e devolve o saldo
        if(this.leilaoSelecionado.maior_lance && this.leilaoSelecionado.maior_lance.id_participante == this.user_id){

          if(response.maior_lance.id_participante != this.leilaoSelecionado.maior_lance.id_participante)
            this.moedas += parseInt(this.meu_lance);
        }

        //atualiza o lance
        this.leilaoSelecionado.maior_lance = response.maior_lance;
        
        if (!this.lanceAtualizado || response.maior_lance.moedas > this.valor_lance) {
          this.valor_lance = response.maior_lance ? response.maior_lance.moedas : this.leilaoSelecionado.lance_minimo;
          this.lanceAtualizado = false;
        }
  
      },
      error: (error) => {
        console.error('Erro ao obter o maior lance do leilão:', error);
      }
    });
  }

  iniciarTimer(leilao: any) {
    const dataFimDate = new Date(leilao.data_fim).getTime();

    leilao.timer = setInterval(() => {
      const agora = new Date().getTime();
      const distancia = dataFimDate - agora;

      if (distancia < 0) {
        clearInterval(leilao.timer);
        leilao.tempoRestante = 'Leilão finalizado';
      } else {

        const dias = Math.floor(distancia / (1000 * 60 * 60 * 24));
        const horas = Math.floor((distancia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutos = Math.floor((distancia % (1000 * 60 * 60)) / (1000 * 60));
        const segundos = Math.floor((distancia % (1000 * 60)) / 1000);

        leilao.tempoRestante = `${dias}d ${horas}h ${minutos}m ${segundos}s`;

      }
    }, 1000);
  }

  decrementar() {
    if (this.valor_lance > this.leilaoSelecionado.lance_minimo) {
      this.valor_lance -= 20;
    }
  }

  incrementar() {
    if (this.valor_lance <= this.leilaoSelecionado.maior_lance.moedas) {
      this.lanceAtualizado = true; 
      this.valor_lance = parseInt(this.valor_lance.toString(), 10) + 20;
    }
  }
  
  resetLanceAtualizado() {
    this.lanceAtualizado = false;
  }

  closeModal() {

    this.valor_lance = 0;
    this.mostrarLanceContainer = false;
    this.mensagemErro = '';
    clearInterval(this.atualizarLeilaoAberto);

  }
  
}
