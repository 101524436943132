import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild, EventEmitter, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { NgForm , UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { timeout, catchError } from 'rxjs/operators'; // timeout e catchError vêm do rxjs/operators
import { throwError } from 'rxjs'; // throwError vem de 'rxjs'


@Component({
  selector: 'cadastro-pontos',
  templateUrl: './cadastro-pontos.component.html',
  styleUrls: ['./cadastro-pontos.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CadastroPontosComponent implements OnInit {

  user_id;
  user_name;
  foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";

  pontos = 0;
  moedas = 0;
  loaded_data = false;

  pontos_ganhos;
  moedas_ganhos;
  modal_ganhou = false;

  isUploading = false;

  confirmacaoAceita: boolean = false;

  modal_aviso = false;
  
  scannear = false;
  regulamento = false;
  is_desktop = false;
  debug = '';
  carregando = true;

  showContent: boolean = false;
  timing = false;

  imageSrc: any;
  myForm = new UntypedFormGroup({
    file: new UntypedFormControl('', [Validators.required]),
    fileSource: new UntypedFormControl('', [Validators.required])
  });

  constructor(private router: Router, private http: HttpClient) {  }
  
  ngOnInit() {

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');

    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    this.obter_dados();

    setTimeout(() => {
      this.timing = true;
    }, 15000);

  }

  obter_dados() {

    this.http.get<any>(environment.API_URL.concat('pontos/index?id_participante=' + this.user_id)).subscribe(data => {
      this.pontos = data.pontos;
      this.moedas = data.moedas;
      this.loaded_data = true;
    });

  }

  animacao_saldo() {
    this.modal_ganhou = true;
    setTimeout(() => { 
      this.modal_ganhou = false 
      location.reload();
    }, 5000);
 

  }

  atualizar_saldos($event) {

    setTimeout(() => {
      this.modal_ganhou = false;
      this.router.navigate(['/home']);
    }, 4000);

  }

  abrir_cam(){

    if(this.scannear){
      this.scannear = false;
      setTimeout(()=>{
        this.scannear = true;
      }, 1000);
    }
    else{
      this.scannear = true;
    }

  }

  enviar_qr(event) {

    if(event){
  
      console.log('Iniciando requisição HTTP...');
      this.debug += 'Leitura completa, validando dados...';
  
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      });
  
      // Configurando timeout de 10 segundos (10000 milissegundos)
      this.http.post(environment.API_URL.concat('pontos/cadastrar_codigo_qr'), {
        codigo_qr: event,
        id_participante: this.user_id
      }, { headers: headers })
      .pipe(
        timeout(10000), // Timeout após 10 segundos
        catchError(error => {
          console.error('Erro durante a requisição HTTP:', error);
          this.debug += "<br><small> Erro, tente novamente em alguns minutos. <br> contacte o administrador do sistema caso persista</small>";
          return throwError(error); // Lançando erro para ser tratado
        })
      )
      .subscribe((data: any) => {
        this.carregando = false;
        console.log('Requisição HTTP completada.');
        this.debug += "<br> Dados Obtidos";
        
        if(data.status === 1){
          this.debug += "<br> Parabéns, você ganhou " + data.pontos + " pontos e " + data.moedas + " moedas!";
          this.pontos_ganhos = data.pontos;
          this.moedas_ganhos = data.moedas;
          this.animacao_saldo();
          this.playCoinSound();
        } else {
          this.debug += "<br>" + JSON.stringify(data.msg);
        }
      }, (error) => {
        this.carregando = false;
        console.error('Erro na requisição HTTP:', error);
        this.debug += "<br><small> Erro, tente novamente em alguns minutos. <br> contacte o administrador do sistema caso persista</small>";
      });
  
    }
  }

  playCoinSound() {
    let audio = new Audio('assets/audio/audioCoin.mpeg');
    audio.play();
  }

  upload_image(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      // Verificar tipo de arquivo
      let ext = file.type.split('/')[1].toLowerCase();
      let allowedTypes = ['png', 'jpg', 'jpeg'];

      if (!allowedTypes.includes(ext)) {
          alert("Formato de arquivo não suportado. Faça upload de uma imagem JPG ou PNG.");
          return;
      }

      // Limitar tamanho do arquivo
      if (file.size > 16000000) { // 16MB
          alert("O arquivo é muito grande. Selecione um arquivo menor que 16MB.");
          return;
      }

      const img = new Image();
      img.onload = () => {
          const width = img.width;
          const height = img.height;

          if (width > 4500 || height > 4500) {
              alert("A imagem é muito grande. As dimensões máximas permitidas são 4500x4500 pixels.");
              return;
          }

          // Redimensionar a imagem usando canvas
          const canvas = document.createElement('canvas');
          const maxWidth = 500; // Defina a nova largura
          const scaleFactor = maxWidth / width; // Fator para manter a proporção

          canvas.width = maxWidth;
          canvas.height = height * scaleFactor;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Converter a imagem para blob ou base64
          canvas.toBlob((blob) => {
              const newFile = new File([blob], file.name, { type: file.type });

              const newReader = new FileReader();
              newReader.onload = () => {
                  this.imageSrc = newReader.result as string;
                  this.myForm.patchValue({
                      fileSource: newReader.result
                  });
                  this.submit(); // Submeter a imagem redimensionada
              };
              newReader.readAsDataURL(newFile);
          }, file.type); // O tipo de arquivo é o mesmo do original
      };

      img.onerror = () => {
          alert("Erro ao processar a imagem. Por favor, tente outro arquivo.");
      };

      img.src = URL.createObjectURL(file);
    }
  }

  submit() {
    if (this.isUploading) {
        return; 
    }

    this.isUploading = true;

    this.http.post(environment.API_URL.concat('pontos/upload_foto_qr/' + this.user_id), this.myForm.value)
        .subscribe((data: any) => {
                this.isUploading = false;
                if (data.status === 2){
                  this.debug += "<br> Parabéns, você ganhou " + data.pontos + " pontos e " + data.moedas + " moedas!";
                  this.pontos_ganhos = data.pontos;
                  this.moedas_ganhos = data.moedas;
                  this.animacao_saldo();
                  this.playCoinSound();
                }
                else if (data.status === 1) {
                    this.modal_aviso = true;
                } else {
                    alert(data.message);
                    console.log(data);
                }           
        });
  }

  redirectHome(){
    this.modal_aviso = false;
    this.carregando = true;
    this.router.navigate(['/home']);
  }	  

}
